<template>
  <GlobalHeader :theme="headerTheme" :slug="pageSlug" />
  <main id="content" v-bind="$attrs">
    <PageHead :blok="blok" :date="date" :name="name" :slug="slug" />
    <StoryblokComponent
      v-for="section in blok.sections"
      :key="section._uid"
      :blok="section"
    />
  </main>
  <GlobalFooter />
</template>

<script lang="ts" setup>
import { PageBlokProps } from '@/types';

// Props
const props = defineProps<{
  blok: PageBlokProps;
  date: string;
  name: string;
  slug: string;
}>();

const headerTheme = computed(() => {
  return props.blok.sections &&
    props.blok.sections.length > 0 &&
    props.blok.sections[0].theme
    ? props.blok.sections[0].theme
    : 'chalk';
});

const pageSlug = computed(() => {
  return props.slug;
});
</script>
